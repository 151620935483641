import PropTypes from "prop-types";
import React from "react";

const BrandLogoCompetitions = ({ data, sliderClassName, spaceBottomClass }) => {
  return (
    <div
      className={`${sliderClassName ? sliderClassName : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="team-img">
        <img
          src={process.env.PUBLIC_URL + data.image}
          alt=""
          className="img-fluid"
          height="200"
        />
      </div>
      <div>{data.name}</div>
    </div>
  );
};

BrandLogoCompetitions.propTypes = {
  data: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default BrandLogoCompetitions;
