import axios from "axios";
import { API_ROOT } from "../../configs/env-vars";
import moment from "moment";

export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const SET_LOCATION = "SET_LOCATION"
export const CLEAR_LOCATION = "CLEAR_LOCATION"
export const RESET_LOCATION = "RESET_LOCATION"


// fetch locations
export const fetchLocations = (locations) => {
  return (dispatch) => {
    axios
      .get(`${API_ROOT}/locations`)
      .then((response) => {
        const locations = response.data.results;
        dispatch({
          type: FETCH_LOCATIONS_SUCCESS,
          payload: locations,
        });
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
};

export const setLocation = (id, name) => {
  let set_time = moment().format("YYYY-MM-DD HH:mm:ss")
  return (dispatch) => {
    dispatch({type: SET_LOCATION, payload: {id: id, name: name, set_time: set_time}})
  }
}

export const clearLocation = () => {
  return (dispatch) => {
    dispatch({type: CLEAR_LOCATION, payload: {}})
  }
}

export const resetLocation = () => {
  return (dispatch) => {
    dispatch({type: RESET_LOCATION, payload: {}})
  }
}