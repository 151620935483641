import { CLEAR_LOCATION, FETCH_LOCATIONS_SUCCESS, SET_LOCATION, RESET_LOCATION } from "../actions/locationsActions";

const initState = {
  locations: [],
  location: {}
};

const locationsReducer = (state = initState, action) => {
  const locationsState = state,
    payLoad = action.payload
  if (action.type === FETCH_LOCATIONS_SUCCESS) {
    return {
      ...locationsState,
      locations: action.payload,
    };
  }

  if(action.type === SET_LOCATION){
    return {
      ...locationsState,
      location: {...payLoad}
    };
  }

  if(action.type === CLEAR_LOCATION){
    return {
      ...locationsState,
      location: {...payLoad}
    };
  }

  if(action.type === RESET_LOCATION){
    return {
      locations: [],
      location: {}
    };
  }

  return state;
};

export default locationsReducer;
