import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_ROOT } from "../../configs/env-vars";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import imageData from "../../data/image-slider/location-slider.json";

const CompetitionsVideoContainer = ({ spaceBottomClass }) => {
  const [modalStatus, isOpen] = useState(false);
  const [factText, setFactText] = useState([]);

  const fetchFactData = async () => {
    const result = await axios(`${API_ROOT}/website-data/competition`);
    
    setFactText(result.data.results);
  };
  useEffect(async () => {
    fetchFactData();
  }, []);
  return (
    <div className={`video-popup ${spaceBottomClass ? spaceBottomClass : ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="video-popup__image">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/zebra_images/covers/about-cover1.webp"
                }
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="video-popup__content">
              <h2 className="title mb-30">
                {factText && factText[0] && factText[0].label.length > 0
                  ? factText[0].label
                  : "We have had 94+ competitive teams in the last 8+ years."}
              </h2>
              <p className="text mb-30">
              {factText && factText[1] && factText[1].label.length > 0
                  ? factText[1].label
                  : "Won 70+ regional, provincial, national awards in First Lego League, Zone01, World Robotics Olympiad & Vex Robotics Competition."}
              </p>

              <ModalVideo
                channel="youtube"
                isOpen={modalStatus}
                videoId="uoZhrEjwhl8"
                onClose={() => isOpen(false)}
              />
              <button onClick={() => isOpen(true)}>
                <i className="fa fa-play-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompetitionsVideoContainer.propTypes = {
  data: PropTypes.object,
};

export default CompetitionsVideoContainer;
