import PropTypes from "prop-types";
import React from "react";
import Swiper from "react-id-swiper";
import BrandLogoCompetitions from "../../components/brand-logo/BrandLogoCompetitions";
import competitionsList from "../../data/general/competitions-list.json";
import SectionTitleSeven from "../../components/section-title/SectionTitleSeven";

const CompetitionSlider = ({ spaceBottomClass, spaceTopClass }) => {
  const settings = {
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    grabCursor: true,
    breakpoints: {
      1024: {
        slidesPerView: 5,
      },
      768: {
        slidesPerView: 4,
      },
      640: {
        slidesPerView: 3,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <div
      className={`brand-logo-area ${
        spaceBottomClass ? spaceBottomClass : ""
      }  ${spaceTopClass ? spaceTopClass : ""}`}
    >
      <div className="container">
        <SectionTitleSeven
          titleText="Some Competitions We Compete In"
          positionClass="text-center"
          spaceClass="mb-50"
          borderClass="no-border"
        />
        <div className="brand-logo-active">
          <Swiper {...settings}>
            {competitionsList &&
              competitionsList.map((single, key) => {
                return (
                  <BrandLogoCompetitions
                    data={single}
                    key={key}
                    sliderClassName="swiper-slide"
                    spaceBottomClass="mb-30"
                  />
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

CompetitionSlider.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default CompetitionSlider;
