export const SAVE_CHECKOUT_SUCCESS = "SAVE_CHECKOUT_SUCCESS";

const saveCheckoutSuccess = (studentParentData) => ({
  type: SAVE_CHECKOUT_SUCCESS,
  payload: studentParentData,
});

// fetch
export const saveCheckout = (studentParentData) => {
  return (dispatch) => {
    dispatch(saveCheckoutSuccess(studentParentData));
  };
};
