import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Competitions from "./pages/other/Competitions";
import BranchRoutes from "./BranchRoutes";
import { deleteAllFromCart } from "./redux/actions/cartActions";
import {
  clearLocation,
  setLocation,
  resetLocation,
} from "./redux/actions/locationsActions";
import { clear } from "redux-localstorage-simple";

// home pages
const ZebraHomePage = lazy(() => import("./pages/home/ZebraHomePage"));
const TermsAndCopyright = lazy(() => import("./pages/other/TermsAndCopyright"));

//locations page
const Locations = lazy(() => import("./pages/locations/Locations"));

//payment pages
const PaymentDecline = lazy(() => import("./pages/payment/PaymentDecline"));
const PaymentSuccess = lazy(() => import("./pages/payment/PaymentSuccess"));

// other pages
const Company = lazy(() => import("./pages/other/Company"));
const Franchise = lazy(() => import("./pages/other/Franchise"));
const Careers = lazy(() => import("./pages/other/Careers"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const Policy = lazy(() => import("./pages/other/Policy"));
const Contact = lazy(() => import("./pages/other/Contact"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = ({ dispatch }) => {
  useEffect(() => {
    dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
        },
      })
    );
  });
  const all_locations = useSelector((state) => state.locationsData.locations);
  const curr_location = useSelector((state) => state.locationsData.location);
  const cartData = useSelector((state) => state.cartData);
  useEffect(() => {
    const clearLocalStorage = () => {
      dispatch(deleteAllFromCart());
      dispatch(resetLocation());
    };
    (!curr_location || !curr_location.id) && clear();
    (!curr_location || !curr_location.id) &&
      cartData.length > 0 &&
      clearLocalStorage();
  }, [curr_location, cartData]);

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              {typeof curr_location != undefined &&
              all_locations &&
              curr_location &&
              curr_location.id &&
              all_locations.filter((l) => {
                return (
                  l.upcoming != 1 &&
                  (l.branch_name == curr_location.name ||
                    l.short_name == curr_location.name) &&
                  l.branch_id == curr_location.id
                );
              }).length > 0 ? (
                <Switch>
                  <Route
                    path={process.env.PUBLIC_URL + "/company"}
                    component={Company}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/franchise"}
                    component={Franchise}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/careers"}
                    component={Careers}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/termsAndCopyright"}
                    component={TermsAndCopyright}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/faq"}
                    component={FAQ}
                  />

                  {/* Locations page */}
                  <Route
                    path={process.env.PUBLIC_URL + "/locations"}
                    component={Locations}
                  />

                  {/* Other pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/about"}
                    component={Company}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/contact"}
                    component={Contact}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/competitions"}
                    component={Competitions}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/trialform"}
                    component={Contact}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/not-found"}
                    component={NotFound}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/payment-success"}
                    component={PaymentSuccess}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/payment-decline"}
                    component={PaymentDecline}
                  />

                  <Route exact component={BranchRoutes}></Route>
                </Switch>
              ) : (
                <Switch>
                  <Route
                    path={process.env.PUBLIC_URL + "/company"}
                    component={Company}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/franchise"}
                    component={Franchise}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/careers"}
                    component={Careers}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/termsAndCopyright"}
                    component={TermsAndCopyright}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/faq"}
                    component={FAQ}
                  />

                  {/* Locations page */}
                  <Route
                    path={process.env.PUBLIC_URL + "/locations"}
                    component={Locations}
                  />

                  {/* Other pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/about"}
                    component={Company}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/contact"}
                    component={Contact}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/competitions"}
                    component={Competitions}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/trialform"}
                    component={Contact}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/not-found"}
                    component={NotFound}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/payment-success"}
                    component={PaymentSuccess}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/payment-decline"}
                    component={PaymentDecline}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/policy"}
                    component={Policy}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/:branch_name"}
                    component={BranchRoutes}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    component={ZebraHomePage}
                  ></Route>

                  <Route exact component={NotFound} />
                </Switch>
              )}
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
