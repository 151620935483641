/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

export function LocationDialog() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (window.location.href.includes("location") == false) {
        setShowModal(true);
      }
    }, 1000 * 10 * 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHide = async () => {
    setTimeout(() => {
      setShowModal(false);
    }, 100);
  };

  return (
    <Modal show={showModal} onHide={onHide}>
      <div
        className="modal-content"
        style={{
          display: "block",
          paddingLeft: "0px",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-6 bg-img pl-0"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL +
                  "/assets/zebra_images/location-maps/location_select.jpg"
                })`,
              }}
            ></div>
            <div className="col-md-6 text-center mt-5 py-5 px-sm-5 my-auto ">
              <br />
              <br />
              <h2 className="pt-sm-3">Welcome</h2>
              <p className="text-muted">
                Please select your preferred location to enhance your
                experience.
              </p>
              <div className="funfact-content funfact-res text-center">
                <div
                  className="funfact-btn funfact-btn--round-shape funfact-btn-red btn-hover"
                  style={{ marginTop: "0px" }}
                >
                  <a href={process.env.PUBLIC_URL + "/locations"}>
                    Choose Location
                  </a>
                </div>
              </div>
              <div className="pt-3 ">
                <small>
                  <button
                    type="button"
                    style={{ fontSize: "100%" }}
                    className="btn btn-link"
                    onClick={onHide}
                  >
                    <a
                      data-dismiss="modal"
                      aria-label="Close"
                      className="text-center text-muted"
                    >
                      No, I'll skip for now.
                    </a>
                  </button>
                </small>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
