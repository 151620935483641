export const formatDistance = (distance) => {
  return parseFloat(distance / 1000).toFixed(1);
};

export const formattedBranchName = (location) => {
  return location.replace(/\s+/g, "-").toLowerCase();
};

export const getFormattedNumber = (value) => {
  value = value.replace(/[^\d]/g, "");
  if (value.length <= 0) {
    return ``;
  } else if (value.length < 4) {
    return `(${value}`;
  } else if (value.length < 7) {
    return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  } else {
    return `(${value.slice(0, 3)}) - ${value.slice(3, 6)} - ${
      value.length > 10 ? value.slice(6, 10) : value.slice(6)
    }`;
  }
};

export const trimOffTheTop = (value, extraSpaceReplacement) => {
  let extraSpaces = /[[:space:]]{2,}|\t/g;
  return value.replaceAll(extraSpaces, extraSpaceReplacement).trim();
};

export const getVanillaPhoneNumber = (value) => {
  return (
    value.substring(1, 4) + value.substring(8, 11) + value.substring(14, 18)
  );
};
