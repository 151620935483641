import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import BranchMessageModal from "../addons/BranchMessageModal";
import BranchInfoHeader from "./sub-components/BranchInfoHeader";
import axios from "axios";
import { API_ROOT } from "../../configs/env-vars";

const HeaderTop = ({
  currency,
  setCurrency,
  currentLanguageCode,
  dispatch,
  borderStyle,
}) => {
  const curr_location = useSelector((state) => state.locationsData.location);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();
  const [messageData, setMessageData] = useState([]);

  const fetchModalMessage = async () => {
    const result = await axios(
      `${API_ROOT}/website-data/modal-message/` + curr_location.id
    );

    setMessageData(result.data.results);
    message === undefined && setMessage(result.data.results);
  };
  useEffect(async () => {
    curr_location && fetchModalMessage();
  }, [curr_location]);

  return curr_location && curr_location.id ? (
    <div
      className={`header-top-wap ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    >
      <div className="header-offer">
        {/* <p style={{ cursor: "pointer" }}>
          Update on{" "}
          <span
            onClick={(e) => {
              setShow(true);
            }}
          >
            COVID-19
          </span>
        </p> */}
      </div>
      <BranchInfoHeader />
      {message && (
        <BranchMessageModal
          onHide={() => setShow(false)}
          show={show}
          title={message[0].title}
          content={message[0].content}
        />
      )}
    </div>
  ) : (
    <div
      className={`header-top-wap ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    ></div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
