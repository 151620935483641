import PropTypes from "prop-types";
import React, { useEffect } from "react";
import axios from "axios";
import CustomForm from "./sub-components/SubscribeEmail";
import { connect, useSelector } from "react-redux";
import { API_ROOT } from "../../configs/env-vars";
import { useToasts } from "react-toast-notifications";

const FooterNewsletter = ({
  spaceBottomClass,
  spaceLeftClass,
  sideMenu,
  colorClass,
  widgetColorClass,
}) => {
  const curr_location = useSelector((state) => state.locationsData.location)
  const { addToast } = useToasts();

  const onValidated = async ({ EMAIL }) => {
    const branch = curr_location.id;

    await axios
      .post(`${API_ROOT}/newsletter`, { email: EMAIL, branch_id: branch })
      .then((res) => {
        addToast("Email successfully subscribed", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        console.log(e);
        addToast("Something went wrong, please contact the center", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return (
    <div
      className={`footer-widget ${spaceBottomClass ? spaceBottomClass : ""} ${
        sideMenu ? "ml-ntv5" : spaceLeftClass ? spaceLeftClass : ""
      } ${widgetColorClass ? widgetColorClass : ""}`}
    >
      <div className="footer-title">
        <h3>SUBSCRIBE</h3>
      </div>
      <div className={`subscribe-style ${colorClass ? colorClass : ""}`}>
        <p>Get E-mail updates about our latest workshops and special offers.</p>
        {/* subscribe email */}
        <CustomForm onValidated={onValidated} />
      </div>
    </div>
  );
};

FooterNewsletter.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  colorClass: PropTypes.string,
  widgetColorClass: PropTypes.string,
};

export default FooterNewsletter;
