import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { formattedBranchName } from "../../helpers/formatter";
import { connect, useSelector } from "react-redux";

const Logo = ({ imageUrl, logoClass }) => {
  const curr_location = useSelector((state) => state.locationsData.location)
  return (
    <div className={`${logoClass ? logoClass : ""}`}>
      {curr_location && curr_location.name ? (
        <Link
          to={{
            pathname: `${process.env.PUBLIC_URL}/${formattedBranchName(
              curr_location.name
            )}`,
            state: {
              branchName: formattedBranchName(curr_location.name),
              branchId: curr_location.id,
            },
          }}
        >
          <img
            alt=""
            src={process.env.PUBLIC_URL + imageUrl}
            className="img-fluid "
          />
        </Link>
      ) : (
        <Link to={`${process.env.PUBLIC_URL}`}>
          {" "}
          <img
            alt=""
            src={process.env.PUBLIC_URL + imageUrl}
            className="img-fluid"
          />
        </Link>
      )}
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
};

export default Logo;
