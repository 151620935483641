import axios from "axios";
import { API_ROOT } from "../../configs/env-vars";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

// fetch products
export const fetchProducts = (branch, type) => {
  return (dispatch) => {
    axios
      .get(`${API_ROOT}/programs?branch_id=${branch}&&type=${type}`)
      .then((response) => {
        const products = response.data.results;
        dispatch(fetchProductsSuccess(products));
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
};
