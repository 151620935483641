import axios from "axios";
import { API_ROOT } from "../../configs/env-vars";

export const FETCH_BATCHES_SUCCESS = "FETCH_BATCHES_SUCCESS";

const fetchBatchesSuccess = (batches) => ({
  type: FETCH_BATCHES_SUCCESS,
  payload: batches,
});

// fetch batches
export const fetchBatches = (batch, type, branchId) => {
  let d = new Date()
  return (dispatch) => {
    type === "programs"
      ? axios
          .get(`${API_ROOT}/programs/${batch}?branchId=${branchId}`)
          .then((response) => {
            const batches = response.data.results;
            dispatch(fetchBatchesSuccess(batches));
          })
          .catch((err) => {
            console.log("Error: ", err);
          })
      : axios
          .get(`${API_ROOT}/programs/camp/${batch}?branchId=${branchId}&date=${d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate()}`)
          .then((response) => {
            const batches = response.data.results;
            dispatch(fetchBatchesSuccess(batches));
          })
          .catch((err) => {
            console.log("Error: ", err);
          });
  };
};
