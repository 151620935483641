import React from "react";
import { connect, useSelector } from "react-redux";

const BranchInfoHeader = () => {
  const curr_location = useSelector((state) => state.locationsData.location)

  const pLocation = useSelector((state) =>
    state.locationsData.locations.find(
      (location) =>
        location.branch_id === Number(curr_location.id ? curr_location.id : 7)
    )
  );
  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency">
        <a href={"tel:+" + pLocation.branchphone}>
          <i className="fa fa-phone"></i> {pLocation.branchphone}
        </a>
      </div>
    </div>
  );
};

export default BranchInfoHeader;
