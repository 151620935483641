import axios from "axios";
import React, {useState, useEffect} from "react";
import Timeline from "react-image-timeline";
import { API_ROOT } from "../../configs/env-vars";
import CompetitionsData from "../../data/general/competitions.json";
import "../../assets/css/timeline.css"; // .scss also available
import moment from "moment/moment";

const CompetitionsTimeline = () => {
  const [events, setEvents] = useState([]);

  const fetchCompetitionEvents = async () => {
    const result = await axios(`${API_ROOT}/images/competition/`);
    const data = result.data.results.map((eve) => {
      let date = moment(eve.date, "YYYY-MM-DD")
      let dateYear = date.format("YYYY")
      let dateMonth = date.format("MM")
      let dateDay = date.format("DD")
      return {
        date: new Date(dateYear, dateMonth - 1, dateDay),
        text: eve.text,
        title: eve.title,
        imageUrl: eve.image_url,
        onClick: console.log,
      };
    });

    setEvents(data);
  };

  useEffect(async () => {
    fetchCompetitionEvents();
  }, []);

  // const events = CompetitionsData.map((comp) => ({
  //   date: new Date(comp.year, comp.month, comp.day),
  //   text: comp.text,
  //   title: comp.title,
  //   imageUrl: process.env.PUBLIC_URL + comp.imageUrl,
  //   onClick: console.log,
  // }));

  return <Timeline events={events} reverseOrder={true} />;
};

export default CompetitionsTimeline;
