import { FETCH_BATCHES_SUCCESS } from "../actions/batchesActions";

const initState = {
  batches: [],
};

const batchesReducer = (state = initState, action) => {
  if (action.type === FETCH_BATCHES_SUCCESS) {
    return {
      ...state,
      batches: action.payload,
    };
  }

  return state;
};

export default batchesReducer;
