import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../../helpers/product";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import moment from "moment";
import { formattedBranchName } from "../../../helpers/formatter";
import { Button } from "react-bootstrap";
import InvalidCartModal from "../../addons/InvalidCartModal";

const MenuCart = ({
  cartData,
  currency,
  deleteFromCart,
  deleteAllFromCart,
}) => {
  const curr_location = useSelector((state) => state.locationsData.location);
  let cartTotalPrice = 0;
  const { addToast } = useToasts();

  const checkCartValidity = () => {
    let valid = true;
    cartData.map((item) => {
      let invalidCartItems = item.selectedProductSize.filter((b) => {
        /*
          check if cart are valid based on:
            1) having a branch_tax
          check if batches are valid based on:
            1) having a price
            2) having a selected_duration
            3) having a end_time
          check if camps are valid based on:
            1) having a camp_id
        */


        if (b.type == "programs") {
          if (typeof b.end_time == "undefined") {
            return true;
          } else if (typeof b.selected_duration == "undefined") {
            return true;
          } else if (typeof b.price == "undefined") {
            return true;
          } else {
            return false;
          }
        } else {
          //YO
          if (typeof b.camp_type_id == "undefined") {
            return true;
          } else {
            return false;
          }
        }
      });
      if (typeof item.branch_tax == "undefined" || typeof item.fee_tax_ind == "undefined") {
        valid =  false;
      }
      if (typeof item.images == "undefined") {
        valid = false;
      }

      if (invalidCartItems.length > 0) {
        valid = false;
      }
    });
    if (valid == false) {
      return false;
    } else {
      console.log("Full valid cart");
      return true;
    }
  };

  //fix me
  const calculateFinalProductPrice = (single) => {
    return single.selectedProductSize.reduce(function (a, b) {
      return a + b.price;
    }, 0);
  };

  return (
    <div className="shopping-cart-content">
      {cartData && cartData.length > 0 ? (
        checkCartValidity() ? (
          <Fragment>
            <ul>
              {cartData.map((single, key) => {
                const discountedPrice = getDiscountPrice(
                  single.price,
                  single.discount
                );

                const finalProductPrice = calculateFinalProductPrice(single);

                cartTotalPrice += finalProductPrice * single.quantity;

                return (
                  <li className="single-shopping-cart" key={key}>
                    <div className="shopping-cart-img">
                      <Link
                        to={
                          single.selectedProductSize[0].type === "programs"
                            ? process.env.PUBLIC_URL +
                              `/${formattedBranchName(
                                curr_location.name
                              )}/programs/` +
                              single.id
                            : process.env.PUBLIC_URL +
                              `/${formattedBranchName(
                                curr_location.name
                              )}/camps/` +
                              single.id
                        }
                      >
                        <img
                          alt=""
                          src={
                            single.image
                              ? process.env.PUBLIC_URL +
                                "/assets/zebra_images/course-specific/" +
                                single.image[0] +
                                "-sm.webp"
                              : process.env.PUBLIC_URL +
                                "/assets/zebra_images/robot.gif"
                          }
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="shopping-cart-title">
                      <h4>
                        <Link
                          to={
                            single.selectedProductSize[0].type === "programs"
                              ? process.env.PUBLIC_URL +
                                `/${formattedBranchName(
                                  curr_location.name
                                )}/programs/` +
                                single.id
                              : process.env.PUBLIC_URL +
                                `/${formattedBranchName(
                                  curr_location.name
                                )}/camps/` +
                                single.id
                          }
                        >
                          {" "}
                          {single.name}{" "}
                        </Link>
                      </h4>
                      <h6>Qty: {single.quantity}</h6>
                      <span>
                        {currency.currencySymbol +
                          calculateFinalProductPrice(single)}
                      </span>
                    </div>
                    <div className="shopping-cart-delete">
                      <button onClick={() => deleteFromCart(single, addToast)}>
                        <i className="fa fa-times-circle" />
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="shopping-cart-total">
              <h4>
                Total :{" "}
                <span className="shop-total">
                  {currency.currencySymbol + cartTotalPrice.toFixed(2)}
                </span>
              </h4>
            </div>
            <div className="shopping-cart-btn btn-hover text-center">
              <Link
                className="default-btn"
                to={
                  process.env.PUBLIC_URL +
                  `/${formattedBranchName(curr_location.name)}/cart`
                }
              >
                checkout
              </Link>
              {/* <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link> */}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <InvalidCartModal
              show={true}
              onHide={() => {
                deleteAllFromCart(addToast);
              }}
            />
          </Fragment>
        )
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func,
  deleteAllFromCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cLocation: state.locationsData.location,
  };
};

export default connect(mapStateToProps)(MenuCart);
