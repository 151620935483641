import PropTypes from "prop-types";
import React from "react";
import { multilanguage } from "redux-multilanguage";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { formattedBranchName } from "../../helpers/formatter";
import { HashLink as Link } from "react-router-hash-link";
import { LocationDialog } from "../../pages/home/components/LocationDialog";
const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  const curr_location = useSelector((state) => state.locationsData.location)
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            {curr_location && curr_location.name ? (
              <Link
                to={
                  process.env.PUBLIC_URL +
                  `/${formattedBranchName(curr_location.name)}/programs`
                }
              >
                {strings["classes"]}
              </Link>
            ) : (
              <Link to={process.env.PUBLIC_URL + "/#ccc-section"}>
                {strings["classes"]}
              </Link>
            )}
          </li>

          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
              {strings["franchise"]}
            </Link>
          </li> */}
          <li>
            {curr_location && curr_location.name ? (
              <Link
                to={
                  process.env.PUBLIC_URL +
                  `/${formattedBranchName(curr_location.name)}/camps`
                }
              >
                {strings["camps"]}
              </Link>
            ) : (
              <Link to={process.env.PUBLIC_URL + "/#ccc-section"}>
                {strings["camps"]}
              </Link>
            )}
          </li>
          <li>
            {curr_location && curr_location.name ? (
              <Link to={process.env.PUBLIC_URL + `/competitions`}>
                {strings["competitions"]}
              </Link>
            ) : (
              <Link to={process.env.PUBLIC_URL + "/competitions"}>
                {strings["competitions"]}
              </Link>
            )}
          </li>
          {curr_location && curr_location.name ? (
            <li>
              <Link
                to={
                  process.env.PUBLIC_URL +
                  `/${formattedBranchName(curr_location.name)}/events`
                }
              >
                {strings["events"]}
              </Link>
            </li>
          ) : (
            ""
          )}
          <li>
            <Link to={process.env.PUBLIC_URL + "/company"}>
              {strings["why"]}
              {/* {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )} */}
            </Link>
            {curr_location && curr_location.name ? (
              <ul className="submenu">
                <li>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      `/${formattedBranchName(curr_location.name)}/company`
                    }
                  >
                    {strings["company"]}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/franchise"}>
                    {strings["franchise"]}
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      `/${formattedBranchName(curr_location.name)}/careers`
                    }
                  >
                    {strings["careers"]}
                  </Link>
                </li> */}
                <li>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      `/${formattedBranchName(curr_location.name)}/faq`
                    }
                  >
                    {strings["general"]}
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      `/${formattedBranchName(curr_location.name)}/coaches`
                    }
                  >
                    {strings["coaches"]}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://blog.zebrarobotics.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {strings["blog"]}
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="submenu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/company"}>
                    {strings["company"]}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/franchise"}>
                    {strings["franchise"]}
                  </Link>
                </li>
                {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/careers"}>
                    {strings["careers"]}
                  </Link>
                </li> */}
                <li>
                  <Link to={process.env.PUBLIC_URL + "/faq"}>
                    {strings["general"]}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://blog.zebrarobotics.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {strings["blog"]}
                  </a>
                </li>
              </ul>
            )}
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/contact"}>
              {curr_location && curr_location.name && (
                <span>
                  <i className="fa fa-map-marker" /> {curr_location.name}
                </span>
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={process.env.PUBLIC_URL + "/contact"}>
                  <i className="fa fa-home mr-10"></i>
                  {strings["contact_us"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/locations"}>
                  <i className="fa fa-location-arrow mr-10"></i>
                  {strings["change_location"]}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      {curr_location && curr_location.name ? "" : <LocationDialog />}
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
  cLocation: state.locationsData.location
  }
}

export default connect(mapStateToProps)(multilanguage(NavMenu));
