import React, { Fragment, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Route, Redirect, Switch } from "react-router";
import { deleteAllFromCart } from "./redux/actions/cartActions";
import { connect } from "react-redux";
import {
  clearLocation,
  setLocation,
  resetLocation,
} from "./redux/actions/locationsActions";

//Branch home page
const BranchHomePage = lazy(() => import("./pages/home/BranchHome"));

// shop pages

const ShopPage = lazy(() => import("./pages/shop/ShopPage"));
const EventsPage = lazy(() => import("./pages/shop/Events"));

//program pages
const StreamDetails = lazy(() =>
  import("./pages/shop-product/StreamDetails")
);

// other pages
const Company = lazy(() => import("./pages/other/Company"));
const Franchise = lazy(() => import("./pages/other/Franchise"));
const Careers = lazy(() => import("./pages/other/Careers"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const Coaches = lazy(() => import("./pages/other/Coaches"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Policy = lazy(() => import("./pages/other/Policy"));
const TermsAndCopyright = lazy(() => import("./pages/other/TermsAndCopyright"));

const Zordle = lazy(() => import("./pages/other/Zordle"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const BranchRoutes = (props) => {
  const clearDate = "2022-04-08-00-00";
  const branch_name = props.match.params.branch_name;
  const all_locations = useSelector((state) => state.locationsData.locations);
  const curr_location = useSelector((state) => state.locationsData.location);
  const location = useSelector((state) =>
    state.locationsData.locations.find(
      (location) =>
        location.upcoming == 0 && (location.short_name == branch_name ||
        location.branch_name == branch_name)
    )
  );

  useEffect(() => {
    const setLocationLocalStorage = () => {
      if (!curr_location.name && props.cartData) {
        props.deleteAllFromCart();
      } else if (location && !curr_location.name) {
        props.setLocation(location.branch_id, location.short_name);
      } else if (location && location.short_name != curr_location.name) {
        props.deleteAllFromCart();
        props.setLocation(location.branch_id, location.short_name);
      }
    };
    ((location && (curr_location || (curr_location && !curr_location.name))) ||
      ((curr_location || (curr_location && !curr_location.name)) &&
        props.cartData &&
        !location) ||
      (location &&
        curr_location &&
        location.short_name != curr_location.name)) &&
      setLocationLocalStorage();
    // setBranchCookie();
  }, [all_locations, location, curr_location]);

  const getRoutes = () => {
    if (curr_location && curr_location.name) {
      return (
        <Switch>
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/events"}
            component={EventsPage}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/programs"}
            component={ShopPage}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/camps"}
            component={ShopPage}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/competitions"}
            component={ShopPage}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/company"}
            component={Company}
          />

          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/franchise"}
            component={Franchise}
          />

          <Route
            path={process.env.PUBLIC_URL + "/:branch_name/contact"}
            component={Contact}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:branch_name/termsAndCopyright"}
            component={TermsAndCopyright}
          />

          <Route
            path={process.env.PUBLIC_URL + "/:branch_name/policy"}
            component={Policy}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/careers"}
            component={Careers}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/faq"}
            component={FAQ}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/coaches"}
            component={Coaches}
          />
                            <Route
                    path={process.env.PUBLIC_URL + "/:branch_name/zordle"}
                    component={Zordle}
                  />
          <Route
            exact
            path={[
              process.env.PUBLIC_URL + "/:branch_name/programs/:id",
              process.env.PUBLIC_URL + "/:branch_name/camps/:id",
            ]}
            component={StreamDetails}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/cart"}
            component={Cart}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:branch_name/checkout"}
            component={Checkout}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:branch_name"}
            component={BranchHomePage}
          ></Route>
          <Route
            exact
            path={process.env.PUBLIC_URL + "/"}
            component={BranchHomePage}
          ></Route>
        </Switch>
      );
    } else if ( !location &&  (!curr_location || !curr_location.name)) {
      return (
        <div>
          <Redirect to="/" />
        </div>
      );
    } else {
      return null
      
    }
  };

  return (
    <Fragment>
      {all_locations.length > 0 ? (
        getRoutes()
      ) : (
        <div className="flone-preloader-wrapper">
          <div className="flone-preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      )}{" "}
      ={/* Homepages */}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    locations: state.locationsData.locations,
    currLocation: state.locationsData.location,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteAllFromCart: () => {
      dispatch(deleteAllFromCart());
    },
    setLocation: (id, name) => {
      dispatch(setLocation(id, name));
    },
    clearLocation: () => {
      dispatch(clearLocation());
    },
    resetLocation: () => {
      dispatch(resetLocation());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BranchRoutes);
