// get products
export const getProducts = (products, category, type, limit) => {
  const finalProducts = category
    ? products.filter(
        (product) => product.category.filter((single) => single === category)[0]
      )
    : products;

  if (type && type === "new") {
    const newProducts = finalProducts.filter((single) => single.new);
    return newProducts.slice(0, limit ? limit : newProducts.length);
  }
  if (type && type === "bestSeller") {
    return finalProducts
      .sort((a, b) => {
        return b.saleCount - a.saleCount;
      })
      .slice(0, limit ? limit : finalProducts.length);
  }
  if (type && type === "saleItems") {
    const saleItems = finalProducts.filter(
      (single) => single.discount && single.discount > 0
    );
    return saleItems.slice(0, limit ? limit : saleItems.length);
  }
  return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

// get products based on name
export const getSearchedProducts = (products, keyword) => {
  const searchedProducts = keyword
    ? products.filter((product) =>
        product.name
          .toString()
          .toLowerCase()
          .includes(keyword.toString().toLowerCase())
      )
    : products;

  return searchedProducts;
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
  let productInCart = cartItems.filter(
    (single) =>
      single.id === product.id &&
      (single.selectedProductColor
        ? single.selectedProductColor === color
        : true) &&
      (single.selectedProductSize ? single.selectedProductSize === size : true)
  )[0];
  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      return cartItems.filter(
        (single) =>
          single.id === product.id &&
          single.selectedProductColor === color &&
          single.selectedProductSize === size
      )[0].quantity;
    } else {
      return cartItems.filter((single) => product.id === single.id)[0].quantity;
    }
  } else {
    return 0;
  }
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue) => {
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      return products.filter((product) => {
        return (
          product.category.filter((single) => {
            // console.log("for", product, "Single vs sort", single, "vs", sortValue, "final",  single === sortValue)
            return single === sortValue;
          }).length > 0
        );
      });
    }
    if (sortType === "tag") {
      return products.filter(
        (product) =>
          product.tag && product.tag.filter((single) => single === sortValue)[0]
      );
    }
    if (sortType === "color") {
      return products.filter(
        (product) =>
          product.variation &&
          product.variation.filter((single) => single.color === sortValue)[0]
      );
    }
    if (sortType === "size") {
      return products.filter(
        (product) =>
          product.variation &&
          product.variation.filter(
            (single) =>
              single.size.filter((single) => single.name === sortValue)[0]
          )[0]
      );
    }
    if (sortType === "search") {
      const includeColumns = ["name", "category", "tag"];

      const searchedData = products.filter((product) => {
        return Object.keys(product).some((key) => {
          return includeColumns.includes(key)
            ? product[key] &&
                product[key].toString().toLowerCase().includes(sortValue)
            : false;
        });
      });
      return searchedData;
    }
    //fix me
    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          let bPrice = [];
          let aPrice = [];
          Object.keys(b.fees).map((i) => {
            b.fees[i].map((fee) => {
              fee.price_range_ind == 1
                ? bPrice.push(fee.course_fee)
                : (bPrice = bPrice);
            });
          });
          Object.keys(a.fees).map((i) => {
            a.fees[i].map((fee) => {
              fee.price_range_ind == 1
                ? aPrice.push(fee.course_fee)
                : (aPrice = aPrice);
            });
          });
          return bPrice[0] - aPrice[0];
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts.sort((a, b) => {
          let bPrice = [];
          let aPrice = [];
          Object.keys(b.fees).map((i) => {
            b.fees[i].map((fee) => {
              fee.price_range_ind == 1
                ? bPrice.push(fee.course_fee)
                : (bPrice = bPrice);
            });
          });
          Object.keys(a.fees).map((i) => {
            a.fees[i].map((fee) => {
              fee.price_range_ind == 1
                ? aPrice.push(fee.course_fee)
                : (aPrice = aPrice);
            });
          });
          return aPrice[0] - bPrice[0];
        });
      }
    }
  }
  return products;
};

// get individual element
const getIndividualItemArray = (array) => {
  let individualItemArray = array.filter(function (v, i, self) {
    return i === self.indexOf(v);
  });
  return individualItemArray;
};

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = [];
  products &&
    products.map((product) => {
      return (
        product.category &&
        product.category.map((single) => {
          return productCategories.push(single);
        })
      );
    });
  const individualProductCategories = getIndividualItemArray(productCategories);
  return individualProductCategories;
};

// get individual tags
export const getIndividualTags = (products) => {
  let productTags = [];
  products &&
    products.map((product) => {
      return (
        product.tag &&
        product.tag.map((single) => {
          return productTags.push(single);
        })
      );
    });
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;
};

// get individual colors
export const getIndividualColors = (products) => {
  let productColors = [];
  products &&
    products.map((product) => {
      return (
        product.variation &&
        product.variation.map((single) => {
          return productColors.push(single.color);
        })
      );
    });
  const individualProductColors = getIndividualItemArray(productColors);
  return individualProductColors;
};

// get individual sizes
export const getProductsIndividualSizes = (products) => {
  let productSizes = [];
  products &&
    products.map((product) => {
      return (
        product.variation &&
        product.variation.map((single) => {
          return single.size.map((single) => {
            return productSizes.push(single.name);
          });
        })
      );
    });
  const individualProductSizes = getIndividualItemArray(productSizes);
  return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = (product) => {
  let productSizes = [];
  product.variation &&
    product.variation.map((singleVariation) => {
      return (
        singleVariation.size &&
        singleVariation.size.map((singleSize) => {
          return productSizes.push(singleSize.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};
export const removeX = (item) => {
  if (item.innerHTML.indexOf(' <i class="fa fa-times-circle"></i>') < 0) {
    return;
  }
  let splitList = item.innerHTML.split(" ");
  let newTag = "";
  splitList.map((string, i) => {
    i < splitList.length - 1 ? (newTag += string + " ") : (newTag += "");
  });
  item.innerHTML = newTag;
};

export const setActiveSort = (e) => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
  );
  let i = 0;
  filterButtons.forEach((item) => {
    item.classList.contains("active") ? removeX(item) : (i = 0);
  });
  filterButtons.forEach((item) => {
    item.classList.remove("active");
  });
  e && e.currentTarget.classList.add("active");
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = (e) => {
  const shopTopFilterWrapper = document.querySelector(
    "#product-filter-wrapper"
  );
  shopTopFilterWrapper.classList.toggle("active");
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null;
  } else {
    shopTopFilterWrapper.style.height =
      shopTopFilterWrapper.scrollHeight + "px";
  }
  e.currentTarget.classList.toggle("active");
};
