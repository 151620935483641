import React from "react";
import { Modal, Button } from "react-bootstrap";


const InvalidCartModal = ({ onHide, show }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          OH NO!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Looks like some of the items in your cart a little moldy. Let's try again with some new fresh items</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onHide();
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvalidCartModal;
