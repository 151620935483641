import PropTypes from "prop-types";
import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import SectionTitleTwo from "../../components/section-title/SectionTitleTwo";
import CompetitionsTimeline from "../../components/timeline/CompetitionsTimeline";
import CompetitionsVideoContainer from "../../components/video-popup-container/CompetitionsVideoContainer";
import LayoutOne from "../../layouts/LayoutOne";
import CompetitionSlider from "../../wrappers/brand-logo/CompetitionSlider";
import { LocationDialog } from "../home/components/LocationDialog";

const Competitions = ({ location }) => {
  const { pathname } = location;

  return (
    <Fragment>
      <MetaTags>
        <title>Zebra Robotics | Competitions</title>
        <meta
          name="Zebra Robotics"
          content="Zebra Robotics competitions page."
        />
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-2"
      >
        <section
          className="container"
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <SectionTitleTwo
            positionClass="text-center"
            titleText="Competitions"
          ></SectionTitleTwo>
          <h4 className="text-center">
            At Zebra Robotics, we believe competitions could be a great
            motivator for improved performance. <br />
            Read{" "}
            <a href="https://blog.zebrarobotics.com/?p=16" target="_blanks">
              {" "}
              here
            </a>{" "}
            on how competition may benefit learning.
          </h4>
        </section>
        <CompetitionsVideoContainer />
        <section
          className="container"
          style={{
            paddingTop: "100px",
            paddingBottom: "0px",
          }}
        >
          <CompetitionSlider spaceBottomClass="pb-35" spaceTopClass="pt-10" />
        </section>
        <section
          className="container"
          style={{
            paddingTop: "50px",
            paddingBottom: "100px",
          }}
        >
          <SectionTitleTwo
            positionClass="text-center"
            titleText="Take a Look At Some of Our Teams"
          ></SectionTitleTwo>
          <CompetitionsTimeline />
        </section>
      </LayoutOne>
    </Fragment>
  );
};

Competitions.propTypes = {
  location: PropTypes.object,
};

export default Competitions;
