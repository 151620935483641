import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import CustomForm from "./sub-components/SubscribeEmail";
import { API_ROOT } from "../../configs/env-vars";

const FooterMedia = () => {
  async function fetchBranchMedia() {
    const result = await axios(
      `${API_ROOT}/branches/social-media/` + curr_location ? curr_location.id : -1
    ).then((result) => {
      return result.data.results;
    });

    return result;
  }

  const curr_location = useSelector((state) => state.locationsData.location)
  const [socialMedia, setSocialMedia] = useState([]);
  let pLocation = useSelector((state) =>
    state.locationsData.locations.find(
      (location) => location.branch_id === Number(curr_location ? curr_location.id : -1)
    )
  );

  useEffect(() => {
    let result = curr_location && pLocation ? pLocation.social_media : fetchBranchMedia();
    setSocialMedia(result);
  }, [curr_location, pLocation]);

  return (
    curr_location && socialMedia ? (
      <div className="footer-list-2">
        <ul>
          {socialMedia && socialMedia.then == undefined? (
            socialMedia.map((m, key) => {
              return (
                <li key={key}>
                  <a
                    className={m.social_media_name}
                    href={m.social_media_base_link + m.social_media_path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={m.icon + " fa-lg"} />
                  </a>
                </li>
              );
            })
          ) : (
            <div className="footer-list-2">
              <ul>
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/zebrarobotics"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook fa-lg" />
                  </a>
                </li>
                <li>
                  <a
                    className="instagram"
                    href="https://www.instagram.com/zebrarobotics/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram fa-lg" />
                  </a>
                </li>
                <li>
                  <a
                    className="twitter"
                    href="https://twitter.com/zebrarobotics?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter fa-lg" />
                  </a>
                </li>
                <li>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/zebra-robotics-inc./"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin fa-lg" />
                  </a>
                </li>
              </ul>
            </div>
          )}
        </ul>
      </div>
    ) : null
  );
};

export default FooterMedia;
