import { SAVE_CHECKOUT_SUCCESS } from "../actions/checkoutActions";

const initState = {
  studentParentData: [],
};

const checkoutReducer = (state = initState, action) => {
  if (action.type === SAVE_CHECKOUT_SUCCESS) {
    return {
      ...state,
      studentParentData: action.payload,
    };
  }

  return state;
};

export default checkoutReducer;
